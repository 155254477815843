import { Link } from 'react-router-dom';
import postList from '../data/posts';

export default function PopularPostWidget({ numb=4 }) {
	return (
		<ul>
			<li>
				{/* <h4>Publicação popular</h4> */}
			</li>
			{/* {postList.slice(0, numb).map(post =>
				<li className="grid gtc-1-2 g-1" key={post.slug}>
					<img src={post.imgs.v} alt={post.title} />
					<div>
						<h5>
							<Link to={`/blog/${post.slug}`}>{post.title}</Link>
						</h5>
						<small className="visible">{post.date}</small>
					</div>
				</li>
			)} */}
		</ul>
	);
}