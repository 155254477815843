import { Helmet } from 'react-helmet';
import projectsList from '../data/projects';
import SectionPageTitle from '../components/SectionPageTitle';
import ProjectOverlay from '../components/ProjectOverlay';
import SectionReviews from '../components/SectionReviews';
import SectionContact from '../components/SectionContact';

export default function Projects() {
	return (
		<>
			<Helmet>
				<title>Service Way Digital: Empresa de Tecnologia da Informação</title>
				<meta name="description" content="Nosso principal objetivo e diferencial é buscar, analisar e resolver todos os problemas com a tecnologia." />
				<meta name="keywords" content="projetos, projetos de TI, plano de negócios, projetos de negócios, soluções em TI, digital, solutions, soluções, ti, tecnologia, informação, tecnologia da informação, mobile, Front-End, Back-End, Sistemas Web, Integração, Modernização de Sistemas, Legados, Segurança da Informação, E-mail Marketing, Identidade Visual, Animação 2D/3D , brasil, app, aplicativo" />
			</Helmet>

			<SectionPageTitle
				pageTitle={'Projetos'} 
				subTitle={'Somos imparáveis'}
			/>

			<section className="big-container">
				<div className="grid gtc-3 g-3">
					{projectsList.map((project, idx) =>
						<ProjectOverlay
							key={idx}
							img={project.img}
							projectName={project.name}
							category={project.category}
							url={project.url}
						/>
					)}
				</div>
			</section>
			
			{/* <SectionReviews col={2} /> */}

			<SectionContact />
		</>
	);
}