import { Helmet } from 'react-helmet';
import servicesList from '../data/services';
import Service from '../components/Service';
import SectionPageTitle from '../components/SectionPageTitle';
import SectionContact from '../components/SectionContact';
import SectionReviews from '../components/SectionReviews';
import CallToAction from '../components/CallToAction';

export default function Services() {
	return (
		<>
			<Helmet>
				<title>Serviços - Service Way Digital: Empresa de Tecnologia da Informação</title>
				<meta name="description" content="Service Way Digital - Serviçoss for Business Services & IT Solutions to your business" />
				<meta name="keywords" content="serviços, serviços de TI, serviço de solução" />
			</Helmet>

			<SectionPageTitle
				pageTitle={'Serviços'} 
				subTitle={'Nosso parceiro de TI'}
			/>

			<section className="container">
				<div className="grid gtc-3 g-2">
					{servicesList.map(service =>
						<Service
							key={service.slug}
							icon={service.icon}
							slug={service.slug}
							title={service.title}
							text={service.text}
						/>
					)}
				</div>
			</section>

			<SectionContact />

			{/* <SectionReviews /> */}

			<CallToAction
				icon={<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z"/></svg>}
				text="Estamos proporcionando a melhor experiência ao cliente"
				btnLabel="Entre em contato conosco agora"
				btnUrl="/contact"
				customClass="mb-8"
			/>
		</>
	);
}