import { Helmet } from 'react-helmet';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import postsList from '../data/posts';
import servicesList from '../data/services';
import projectsList from '../data/projects';
import Service from '../components/Service';
import PostPreview from '../components/PostPreview';
import ProjectOverlay from '../components/ProjectOverlay';
import SectionReviews from '../components/SectionReviews';
import SectionContact from '../components/SectionContact';
import mission from '../imgs/mission.webp';


export default function Home() {
	return (
		<>
			<Helmet>
				<title>Service Way Digital: Empresa de Tecnologia da Informação</title>
				<meta name="description" content="Bem-vindo à Service Way Digital, onde a inovação encontra a confiabilidade, somos seu parceiro confiável para serviços empresariais e soluções de TI" />
				<meta name="keywords" content="negócios, TI, solução de TI, serviços web, modelo de reação" />
			</Helmet>

			<section id="slider">
				<div className="container">
					<div className="grid gtc-2-1">
						<article>
							<h1>Transformando Negócios, <span className="txt-gradient">Capacitando Futuros</span></h1>
							<h2 className="w-60 fw-400">Serviços Empresariais e Soluções de TI</h2>

							<HashLink to="#contact" className="btn">Entre em contato conosco agora</HashLink>
						</article>
					</div>

					<div className="flex space-between g-2">
						<h2><span className="pictogram" /> Consultoria de TI</h2>
						<h2><span className="pictogram" /> Desenvolvimento de software</h2>
						<h2><span className="pictogram" /> Cibersegurança</h2>
					</div>
				</div>
			</section>

			<section>
				<span className="sr-only offset-nav" id="mission" />
				<div className="container grid g-2">
					<div className="print flex">
						<img src={mission} alt="" />
					</div>

					<article>
						<h1>Nossa <span className="txt-gradient">missão</span></h1>
						<p>Bem-vindo à Service Way Digital, onde a inovação encontra a confiabilidade. Fundados nos princípios da tecnologia de ponta e no compromisso inabalável com a excelência, somos o seu parceiro de confiança para serviços empresariais e soluções de TI.</p>
						<p className="mb-3">Nossa missão é impulsionar seu sucesso por meio de soluções de TI inovadoras e personalizadas. Acreditamos no poder da tecnologia para impulsionar os negócios e estamos comprometidos em fornecer soluções que não apenas atendam, mas superem suas expectativas.</p>

						<Link to="/about" className="btn">CONSULTE MAIS INFORMAÇÃO</Link>
					</article>
				</div>
			</section>
			
			<span className="sr-only offset-nav" />
			<section className="accent-start">
				<div className="container">
					<h1 className="txt-gradient">O que nos diferencia</h1>

					<div className="flex g-1 space-between mb-4">
						<h2><span className="pictogram-top" /> Expertise</h2>
						<h2><span className="pictogram-top" /> Colaboração</h2>
						{/* <h2><span className="pictogram-top" /> Proven Track Record</h2> */}
						<h2><span className="pictogram-top" /> Cibersegurança</h2>
						<h2><span className="pictogram-top" /> Desenvolvimento de Software</h2>
					</div>

					<div className="grid g-2 gtc-1-2-1 mb-4">
						<h2 className="self-start mb-0">Por que escolher a Service Way Digital</h2>

						<div>
							<p>Escolher a Service Way Digital significa escolher um parceiro dedicado ao seu sucesso. Combinamos conhecimento técnico com uma abordagem centrada no cliente, garantindo que cada solução que fornecemos contribui para o crescimento do seu negócio.</p>
							<p className="mb-0">Junte-se a nós na jornada rumo à excelência tecnológica. Juntos, vamos moldar um futuro onde o seu negócio não apenas sobreviva, mas também prospere na era digital.</p>
						</div>

						<Link to="/services" className="btn self-end">NOSSOS SERVIÇOS</Link>
					</div>

					<div className="grid gtc-3 g-2">
						{servicesList.map(service =>
							<Service
								key={service.slug}
								icon={service.icon}
								slug={service.slug}
								title={service.title}
								text={service.text}
							/>
						)}
					</div>
				</div>
			</section>

			<section>
				<div className="container">
					<div className="flex g-2 space-between">
						<h1>Nossos <span className="txt-gradient">Projetos</span></h1>
						<Link to="projets" className="btn mb-3">EXPLORE MAIS</Link>
					</div>

					<div className="grid g-3">
						{projectsList.slice(0, 4).map((project, idx) =>
							<ProjectOverlay
								key={idx}
								img={project.img}
								projectName={project.name}
								category={project.category}
								url={project.url}
							/>
						)}
					</div>
				</div>
			</section>

			<section className="bg-line">
				<div className="container txt-center">
					<h3>Pioneirismo Nas Soluções de Amanhã, Capacitando Seu</h3>
					<h1 className="fs-100">Negócio Hoje</h1>
				</div>
			</section>
			
			{/* <section>
				<span className="sr-only offset-nav" id="blog" />
				<div className="container">
					<div className="flex g-2 space-between">
						<h1>Últimas <span className="txt-gradient">Postagens</span></h1>
						<Link to="posts" className="btn mb-3">VER TUDO</Link>
					</div>

					<div className="grid g-2 gtc-3">
						{postsList.slice(0,3).map((post, idx) =>
							<PostPreview
								key={idx}
								img={post.imgs.v}
								title={post.title}
								slug={post.slug}
								date={post.date}
								category={post.category}
							/>
						)}
					</div>
				</div>
			</section>

			<SectionReviews /> */}
			
			<span className="sr-only offset-nav" id="contact" />
			<SectionContact />
		</>
	);
}
