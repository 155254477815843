export default function CallToAction({ icon, text, btnLabel, btnUrl, customClass }) {
	return (
		<section className={`container cta ${customClass}`}>
			<div className="icon">
				{icon}
			</div>
			<h1>{text}</h1>
			<a href={btnUrl} className="btn">{btnLabel}</a>
		</section>
	);
}