export default [
	// {
	// 	icon: <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.738 19.9964C14.8186 19.9988 14.8994 20 14.9806 20C19.3989 20 22.9806 16.4183 22.9806 12C22.9806 7.58172 19.3989 4 14.9806 4C12.4542 4 10.2013 5.17108 8.73522 7H7.51941C3.92956 7 1.01941 9.91015 1.01941 13.5C1.01941 17.0899 3.92956 20 7.51941 20H14.5194C14.5926 20 14.6654 19.9988 14.738 19.9964ZM16.6913 17.721C19.0415 16.9522 20.9806 14.6815 20.9806 12C20.9806 8.68629 18.2943 6 14.9806 6C11.6669 6 8.98059 8.68629 8.98059 12H6.98059C6.98059 10.9391 7.1871 9.92643 7.56211 9H7.51941C5.03413 9 3.01941 11.0147 3.01941 13.5C3.01941 15.9853 5.03413 18 7.51941 18H14.5194C15.0691 18 15.9041 17.9014 16.6913 17.721Z" /></svg>,
	// 	slug: 'cloud-services',
	// 	title: 'Cloud Services',
	// 	text: 'Oferecemos soluções de TI intuitivas e de última geração para potencializar o seu negócio. Nossos serviços empresariais ajudam a agilizar suas operações e obter maior eficiência.',
	// 	content: <>
	// 		<h2>Service Way Digital - Serviços na Nuvem</h2>
	// 		<p>Nós oferecemos soluções de TI intuitivas e de ponta para aprimorar o seu negócio. Nossos serviços empresariais ajudam a simplificar suas operações e alcançar maior eficiência.</p>
	// 		<div className="grid g-2 mb-2">
	// 			<img src={require('../imgs/projects/project1.webp')} alt="Service Way Digital - Serviços na Nuvem" />
	// 			<div>
	// 				<h3>Recursos</h3>
	// 				<p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.</p>
	// 				<ul className="list">
	// 					<li>Lorem solor amet</li>
	// 					<li>Lorem solor amet</li>
	// 					<li>Lorem solor amet</li>
	// 					<li>Lorem solor amet</li>
	// 				</ul>
	// 			</div>
	// 		</div>
	// 		<p>Para mais informações, entre em contato conosco. Estamos à disposição para atendê-lo.</p>
	// 	</>
	// },
	{
		icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path id="it--infrastructure-software_1_" d="M22.5,4.5C22.5,4.776,22.276,5,22,5s-0.5-0.224-0.5-0.5S21.724,4,22,4  S22.5,4.224,22.5,4.5z M20,4c-0.276,0-0.5,0.224-0.5,0.5S19.724,5,20,5s0.5-0.224,0.5-0.5S20.276,4,20,4z M18,4  c-0.276,0-0.5,0.224-0.5,0.5S17.724,5,18,5s0.5-0.224,0.5-0.5S18.276,4,18,4z M30,29.36h-8c-0.199,0-0.36-0.161-0.36-0.36v-4  c0-0.199,0.161-0.36,0.36-0.36h3.64v-3.28h-9.28v3.279H20c0.199,0,0.36,0.161,0.36,0.36v4c0,0.199-0.161,0.36-0.36,0.36h-8  c-0.199,0-0.36-0.161-0.36-0.36v-4c0-0.199,0.161-0.36,0.36-0.36h3.64V21.36H6.36v3.279H10c0.199,0,0.36,0.161,0.36,0.36v4  c0,0.199-0.161,0.36-0.36,0.36H2c-0.199,0-0.36-0.161-0.36-0.36v-4c0-0.199,0.161-0.36,0.36-0.36h3.64V21  c0-0.199,0.161-0.36,0.36-0.36h9.64v-4.28H8c-0.199,0-0.36-0.161-0.36-0.36V3c0-0.199,0.161-0.36,0.36-0.36h16  c0.199,0,0.36,0.161,0.36,0.36v13c0,0.199-0.161,0.36-0.36,0.36h-7.64v4.28H26c0.199,0,0.36,0.161,0.36,0.36v3.64H30  c0.199,0,0.36,0.161,0.36,0.36v4C30.36,29.199,30.199,29.36,30,29.36z M22.36,28.64h7.279v-3.28H22.36V28.64z M12.36,28.64h7.28  v-3.28h-7.28C12.36,25.36,12.36,28.64,12.36,28.64z M2.36,28.64h7.28v-3.28H2.36V28.64z M8.36,15.64h15.28V6.36H8.36V15.64z   M8.36,5.64h15.28V3.36H8.36V5.64z M25,27.36h-2v-0.72h2V27.36z M15,27.36h-2v-0.72h2V27.36z M5,27.36H3v-0.72h2V27.36z"/></svg>,
		slug: 'it-consulting',
		title: 'Consultoria de TI',
		text: 'Oferecemos consultoria especializada em TI para ajudar sua empresa a alcançar seus objetivos de negócios. Nossos consultores experientes trabalharão em estreita colaboração com você para desenvolver e implementar soluções personalizadas.',
		content: <>
			<h2>Service Way Digital - Consultoria de TI</h2>
			<p>Nós oferecemos consultoria especializada em TI para ajudar sua empresa a alcançar seus objetivos de negócios. Nossos consultores experientes trabalharão em estreita colaboração com você para desenvolver e implementar soluções personalizadas.</p>
			<div className="grid g-2 mb-2">
				<img src={require('../imgs/projects/project2.webp')} alt="Service Way Digital - Consultoria de TI" />
				<div>
					<h3>Recursos</h3>
					<p>Nossa consultoria de TI oferece uma ampla gama de recursos para impulsionar a transformação digital da sua empresa. Confira alguns dos recursos abaixo:</p>
					<ul className="list">
						<li>Avaliação detalhada das necessidades de TI da sua empresa</li>
						<li>Desenvolvimento de estratégias de TI alinhadas aos objetivos de negócios</li>
						<li>Implementação de soluções tecnológicas avançadas</li>
						<li>Monitoramento contínuo e suporte técnico</li>
					</ul>
				</div>
			</div>
			<p>Entre em contato conosco para saber mais sobre como nossa consultoria de TI pode impulsionar o sucesso do seu negócio.</p>
		</>
	},
	{
		icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><g id="Coding"><path d="M80.917,377.0605H202V439.16H139a9.8965,9.8965,0,1,0,0,19.7929H373a9.8965,9.8965,0,1,0,0-19.7929H310V377.0605H431.0864a49.9023,49.9023,0,0,0,49.5528-44.1254H31.36A49.91,49.91,0,0,0,80.917,377.0605Z"/><path d="M431.0869,53.0474H80.9175A49.9181,49.9181,0,0,0,31,102.9648V313.1416H481V102.9648A49.9144,49.9144,0,0,0,431.0869,53.0474ZM201.2354,231.6016a9.8993,9.8993,0,1,1-16.4708,10.9863l-36-54a9.9133,9.9133,0,0,1,0-10.9863l36-54a9.8993,9.8993,0,0,1,16.4708,10.9863L168.9,183.0947Zm86.7128-117.5537-45,144a9.899,9.899,0,1,1-18.8964-5.9063l45-144a9.899,9.899,0,1,1,18.8964,5.9063Zm75.2872,74.54-36,54a9.8993,9.8993,0,0,1-16.4708-10.9863L343.1,183.0947l-32.335-48.5068a9.8993,9.8993,0,0,1,16.4708-10.9863l36,54A9.9133,9.9133,0,0,1,363.2354,188.5879Z"/></g></svg>,
		slug: 'software-development',
		title: 'Desenvolvimento de Software',
		text: 'Nosso serviço de desenvolvimento de software é projetado para fornecer soluções personalizadas que atendam às necessidades exclusivas do seu negócio. Utilizando as mais recentes tecnologias e práticas de desenvolvimento ágil, nossa equipe cria aplicativos robustos e escaláveis que impulsionam o crescimento e a inovação.',
		content: <>
			<h2>Service Way Digital - Desenvolvimento de Software</h2>
			<p>Nosso serviço de desenvolvimento de software é projetado para fornecer soluções personalizadas que atendam às necessidades exclusivas do seu negócio. Utilizando as mais recentes tecnologias e práticas de desenvolvimento ágil, nossa equipe cria aplicativos robustos e escaláveis que impulsionam o crescimento e a inovação.</p>
			<div className="grid g-2 mb-2">
				<img src={require('../imgs/projects/project1.webp')} alt="Service Way Digital - Desenvolvimento de Software" />
				<div>
					<h3>Recursos</h3>
					<p>Nosso compromisso com a excelência no desenvolvimento de software oferece uma série de recursos para garantir o sucesso do seu projeto. Confira alguns dos recursos abaixo:</p>
					<ul className="list">
						<li>Análise abrangente de requisitos para entender suas necessidades específicas</li>
						<li>Desenvolvimento ágil para entregas rápidas e adaptação a mudanças de requisitos</li>
						<li>Testes rigorosos para garantir a qualidade e confiabilidade do software</li>
						<li>Implementação de práticas de segurança para proteger seus dados e usuários</li>
					</ul>
				</div>
			</div>
			<p>Entre em contato conosco para discutir como podemos ajudar a transformar sua visão de software em realidade.</p>
		</>
	},
	{
		icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<title>cybersecurity</title>
		<path d="M12,23A11,11,0,1,1,23,12,11,11,0,0,1,12,23ZM12,3a9,9,0,1,0,9,9A9,9,0,0,0,12,3Z"/>
		<path d="M15,11V9A3,3,0,0,0,9,9v2a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V12A1,1,0,0,0,15,11ZM10.5,9a1.5,1.5,0,0,1,3,0v2h-3Z"/>
		<rect width="24" height="24" fill="none"/>
	  	</svg>,
		slug: 'cybersecurity',
		title: 'Cibersegurança',
		text: 'Nossa equipe de especialistas em cibersegurança está aqui para proteger seus ativos digitais e garantir a integridade e confidencialidade dos seus dados. Com soluções personalizadas e tecnologias avançadas, ajudamos a mitigar os riscos cibernéticos e a manter sua organização segura contra ameaças emergentes.',
		content: <>
			<h2>Service Way Digital - Cibersegurança</h2>
			<p>Nossa equipe de especialistas em cibersegurança está aqui para proteger seus ativos digitais e garantir a integridade e confidencialidade dos seus dados. Com soluções personalizadas e tecnologias avançadas, ajudamos a mitigar os riscos cibernéticos e a manter sua organização segura contra ameaças emergentes.</p>
			<div className="grid g-2 mb-2">
				<img src={require('../imgs/projects/project3.webp')} alt="Service Way Digital - Cibersegurança" />
				<div>
					<h3>Recursos</h3>
					<p>Nossos serviços de cibersegurança oferecem uma ampla gama de recursos para proteger sua infraestrutura digital e dados confidenciais. Aqui estão alguns dos recursos que fornecemos:</p>
					<ul className="list">
						<li>Avaliações de vulnerabilidades para identificar pontos fracos em sua infraestrutura</li>
						<li>Implementação de soluções de proteção contra malware e ransomware</li>
						<li>Monitoramento contínuo de ameaças e resposta a incidentes em tempo real</li>
						<li>Consultoria especializada em políticas de segurança e conformidade regulatória</li>
					</ul>
				</div>
			</div>
			<p>Entre em contato conosco para fortalecer a segurança cibernética da sua organização e proteger seus ativos mais valiosos.</p>
		</>
	},
	{
		icon: <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9 13H6V16H9V13ZM11 8H6V11H11V8ZM9 18H6V21H9V18ZM11 3H6V6H11V3ZM15 8H13V11H15V8ZM17 3H13V6H17V3ZM17 13H13V16H17V13ZM19 3V6H21V3H19ZM19 8H21V11H19V8ZM19 13H21V16H19V13ZM3 3H5V21H3V3ZM11 18H13V21H11V18Z" /></svg>,
		slug: 'machine-learning',
		title: 'Machine Learning',
		text: 'Desenvolvemos soluções de aprendizado de máquina personalizadas para ajudar sua empresa a extrair insights valiosos dos dados. Nossa abordagem centrada no cliente garante que as soluções sejam adaptadas às suas necessidades específicas.',
		content: <>
			<h2>Service Way Digital - Machine Learning</h2>
			<p>Nós desenvolvemos soluções de aprendizado de máquina personalizadas para ajudar sua empresa a extrair insights valiosos dos dados. Nossa abordagem centrada no cliente garante que as soluções sejam adaptadas às suas necessidades específicas.</p>
			<div className="grid g-2 mb-2">
				<img src={require('../imgs/projects/project5.webp')} alt="Service Way Digital - Machine Learning" />
				<div>
					<h3>Recursos</h3>
					<p>Nossa expertise em aprendizado de máquina oferece uma série de recursos para impulsionar o sucesso do seu projeto. Confira alguns dos recursos abaixo:</p>
					<ul className="list">
						<li>Análise detalhada dos requisitos de dados e modelagem</li>
						<li>Desenvolvimento e treinamento de modelos de aprendizado de máquina personalizados</li>
						<li>Integração de modelos em sua infraestrutura existente</li>
						<li>Monitoramento e otimização contínuos do desempenho do modelo</li>
					</ul>
				</div>
			</div>
			<p>Entre em contato conosco para saber mais sobre como nossas soluções de aprendizado de máquina podem impulsionar o crescimento do seu negócio.</p>
		</>
	},
	{
		icon: <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 11C15 9.34315 13.6569 8 12 8C10.3431 8 9 9.34315 9 11C9 12.6569 10.3431 14 12 14C13.6569 14 15 12.6569 15 11ZM17.4645 6.53553C18.0503 7.12132 18.0503 8.07868 17.4645 8.66447L12.8284 13.2929C12.5389 13.5824 12.1716 13.7337 11.8 13.7337V16.5C11.8 17.3284 11.1284 18 10.3 18H8.30002C7.4716 18 6.80002 17.3284 6.80002 16.5V13.7337C6.42844 13.7337 6.06112 13.5824 5.77164 13.2929L1.23551 8.75677C0.64972 8.17098 0.649722 7.21362 1.23551 6.62783C1.8213 6.04204 2.77866 6.04204 3.36445 6.62783L7.90058 11.1639C8.10173 11.365 8.25729 11.6104 8.36197 11.8876L8.51825 12.2003L11.2 9.73367L15.4645 13.9999L17.4645 11.9999C17.4645 11.9999 17.4645 11.9999 17.4645 11.9999C18.0503 11.4141 18.0503 10.4567 17.4645 9.87089L17.4645 6.53553ZM12 16.5C12.5523 16.5 13 16.9477 13 17.5C13 18.0523 12.5523 18.5 12 18.5C11.4477 18.5 11 18.0523 11 17.5C11 16.9477 11.4477 16.5 12 16.5Z" /></svg>,
		slug: 'data-analytics',
		title: 'Data Analytics',
		text: 'Nossa equipe de especialistas em análise de dados oferece soluções personalizadas para ajudar sua empresa a transformar dados em insights acionáveis. Desde a coleta e preparação de dados até a visualização e interpretação, estamos aqui para apoiá-lo em todas as etapas do processo de análise.',
		content: <>
			<h2>Service Way Digital - Análise de Dados</h2>
			<p>Nossa equipe de especialistas em análise de dados oferece soluções personalizadas para ajudar sua empresa a transformar dados em insights acionáveis. Desde a coleta e preparação de dados até a visualização e interpretação, estamos aqui para apoiá-lo em todas as etapas do processo de análise.</p>
			<div className="grid g-2 mb-2">
				<img src={require('../imgs/projects/project4.webp')} alt="Service Way Digital - Análise de Dados" />
				<div>
					<h3>Recursos</h3>
					<p>Nossa expertise em análise de dados oferece uma série de recursos para impulsionar o sucesso do seu projeto. Confira alguns dos recursos abaixo:</p>
					<ul className="list">
						<li>Análise abrangente de dados para identificar padrões e tendências</li>
						<li>Desenvolvimento de modelos preditivos para prever resultados futuros</li>
						<li>Visualização de dados avançada para comunicar insights de forma clara e eficaz</li>
						<li>Implementação de soluções de business intelligence para suportar a tomada de decisões informadas</li>
					</ul>
				</div>
			</div>
			<p>Entre em contato conosco para saber mais sobre como nossas soluções de análise de dados podem impulsionar o crescimento do seu negócio.</p>
		</>
	}
];