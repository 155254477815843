import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import logo from '../imgs/logo.png';

export default function Header() {
	const refNavBar = useRef(),
			[isOpen, setIsOpen] = useState(false),
			handleToggle = () => setIsOpen(!isOpen);

	return (
		<header>
			<div className="container g-2 pbk-1">
				<Link to="/">
					<img src={logo} alt="Service Way Digital" />
				</Link>

				<button
					className="nav-toggle"
					aria-controls={refNavBar}
					aria-expanded={isOpen}
					onClick={handleToggle}
				>
					<span className="sr-only">Menu</span>
					<div className="bar1" />
					<div className="bar2" />
					<div className="bar3" />
				</button>

				<nav
					id="navbar"
					ref={refNavBar}
					data-visible={isOpen}
					onFocus={handleToggle}
				>
					<ul className="flex g-2">
						<li><HashLink to="/">Home</HashLink></li>
						<li><HashLink to="about">Sobre</HashLink></li>
						<li><HashLink to="services">Serviços</HashLink></li>
						<li><HashLink to="projects">Projetos</HashLink></li>
						{/* <li><HashLink to="blog">Blog</HashLink></li> */}
						<li><HashLink to="contact" className="btn">Contato</HashLink></li>
					</ul>
				</nav>
			</div>
		</header>
	);
}