export default [
	{
		img: require('../imgs/projects/project1.webp'),
		name: 'Solução de aplicativo',
		category: 'Desenvolvimento de Software',
		url: '#'
	},
	{
		img: require('../imgs/projects/project2.webp'),
		name: 'Design de IU',
		category: 'Portfólio',
		url: '#'
	},
	{
		img: require('../imgs/projects/project3.webp'),
		name: 'Database Checkup',
		category: 'Cibersegurança',
		url: '#'
	},
	{
		img: require('../imgs/projects/project4.webp'),
		name: 'Campanhas de Marketing',
		category: 'Otimização de Negócios',
		url: '#'
	},
	{
		img: require('../imgs/projects/project5.webp'),
		name: 'Assistente de IA',
		category: 'Machine Learning',
		url: '#'
	}
	// {
	// 	img: require('../imgs/projects/project6.webp'),
	// 	name: 'Hosting platform',
	// 	category: 'Cloud',
	// 	url: '#'
	// }
]
