import { Link } from 'react-router-dom';
import SocialLinks from './SocialLinks';
import PopularPostWidget from './PopularPostWidget';
import ContactInfo from './ContactInfo';
import logo from '../imgs/logo.png';

export default function Footer() {
	const currentDate = new Date().getFullYear();
	return (
		<footer>
			<div className="container">
				<div>
					<img src={logo} className="mb-3" alt="Service Way Digital" />
					<p>Transformando Negócios, Capacitando Futuros</p>
					<p className="mb-3">Serviços Empresariais e Soluções de TI</p>
					<SocialLinks />
				</div>

				<ul>
					<li>
						<h4>Link rápido</h4>
					</li>
					<li>
						<Link to='/'>Home</Link>
					</li>
					<li>
						<Link to='/about'>Sobre</Link>
					</li>
					<li>
						<Link to='/services'>Serviços</Link>
					</li>
					<li>
						<Link to='/projects'>Projetos</Link>
					</li>
					{/* <li>
						<Link to='/blog'>Blog</Link>
					</li> */}
					<li>
						<Link to='/contact'>Contato</Link>
					</li>
					<li>
						<Link to='/policy'>Política de Privacidade</Link>
					</li>
				</ul>

				<PopularPostWidget numb={2} />

				<ContactInfo />
			</div>
			

			<p className="txt-center">&copy; Service Way Digital {currentDate}. Todos os direitos reservados. </p>
			{/* Developed by <Link to="/" className="txt-gradient">Travolgi</Link> */}
		</footer>
	);
}