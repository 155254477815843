import ExamplePost from '../views/posts/ExamplePost';
import ExamplePost2 from '../views/posts/ExamplePost2';

export default [
	{
		imgs: {
			v: require('../imgs/posts/post-example6.webp'),
			h: require('../imgs/posts/post-example6-h.webp')
		},
		title: 'Aumentando a Produtividade com Serviços Empresariais Eficientes',
		slug: 'aumentando-a-produtividade-com-servicos-empresariais-eficientes',
		date: '25 Dez 2023',
		category: 'productivity',
		content: <ExamplePost2 />
	},
	{
		imgs: {
			v: require('../imgs/posts/post-example2.webp'),
			h: require('../imgs/posts/post-example2-h.webp')
		},
		title: 'Otimizando Operações com Soluções de TI Confiáveis',
		slug: 'otimizando-operacoes-com-solucoes-confiaveis',
		date: '7 Dez 2023',
		category: 'optimization',
		content: <ExamplePost2 />
	},
	{
		imgs: {
			v: require('../imgs/posts/post-example3.webp'),
			h: require('../imgs/posts/post-example3-h.webp')
		},
		title: 'Desbloqueando o potencial de negócios com serviços avançados de TI',
		slug: 'desbloqueando-o-potencial-de-negocios-com-servicos-de-TI-avancados',
		date: '23 Nov 2023',
		category: 'business',
		content: <ExamplePost />
	},
	{
		imgs: {
			v: require('../imgs/posts/post-example4.webp'),
			h: require('../imgs/posts/post-example4-h.webp')
		},
		title: 'Simplificando processos de negócios por meio de serviços estratégicos de TI',
		slug: 'simplificacao-de-processos-de-negocios-por-meio-de-servicos-de-TI-estrategicos',
		date: '4 Nov 2023',
		category: 'strategic',
		content: <ExamplePost2 />
	},
	{
		imgs: {
			v: require('../imgs/posts/post-example5.webp'),
			h: require('../imgs/posts/post-example5-h.webp')
		},
		title: 'Maximizando a lucratividade com soluções de negócios personalizadas',
		slug: 'maximizando-a-lucratividade-com-solucoes-de-negocios-sob-medida',
		date: '30 Set 2023',
		category: 'profit',
		content: <ExamplePost />
	},
	{
		imgs: {
			v: require('../imgs/posts/post-example1.webp'),
			h: require('../imgs/posts/post-example1-h.webp')
		},
		title: 'A importância das soluções de TI para o crescimento dos negócios',
		slug: 'a-importancia-das-solucoes-de-TI-para-o-crescimento-dos-negocios',
		date: '1 Set 2023',
		category: 'growth',
		content: <ExamplePost />
	}
]