export default [
	{
		question: 'Quais soluções de TI sua empresa oferece',
		answer: 'Nossa empresa oferece uma ampla gama de soluções de TI, incluindo configuração de infraestrutura de rede, serviços de computação em nuvem, soluções de segurança cibernética, desenvolvimento de software e serviços de consultoria de TI.'
	},
	{
		question: 'Como as soluções de TI podem beneficiar meu negócio',
		answer: 'As soluções de TI podem beneficiar o seu negócio, melhorando a eficiência operacional, aumentando a produtividade, garantindo a segurança dos dados, fornecendo acesso a tecnologias avançadas e permitindo uma melhor tomada de decisões através da análise de dados.'
	},
	{
		question: 'Suas soluções de TI são personalizáveis ​​para atender às nossas necessidades de negócios?',
		answer: 'Sim, nossas soluções de TI são altamente personalizáveis ​​e adaptadas para atender aos requisitos exclusivos de cada negócio. Trabalhamos em estreita colaboração com nossos clientes para entender suas necessidades específicas e fornecer soluções personalizadas de acordo.'
	},
	{
		question: 'Você fornece suporte e manutenção contínuos para as soluções de TI',
		answer: 'Sim, oferecemos serviços abrangentes de suporte e manutenção para as soluções de TI que fornecemos. Nossa equipe de profissionais qualificados garante que seus sistemas estejam sempre atualizados, seguros e com desempenho ideal.'
	},
	{
		question: 'Como você garante a privacidade e a segurança dos dados com suas soluções de TI',
		answer: 'Priorizamos a privacidade e a segurança dos dados e implementamos técnicas de criptografia padrão do setor, autenticação multifatorial e protocolos de segurança robustos para proteger suas informações confidenciais. Seguimos políticas rígidas de proteção de dados para garantir a confidencialidade e integridade dos seus dados.'
	}
]