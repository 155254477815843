import { Helmet } from 'react-helmet';
import faqsList from '../data/faqs';
import SectionPageTitle from '../components/SectionPageTitle';
import Faq from '../components/Faq';
import SocialLinks from '../components/SocialLinks';
import CallToAction from '../components/CallToAction';
import mission from '../imgs/mission.webp';
import daniel from '../imgs/team/daniel.webp';
import albert from '../imgs/team/albert.webp';
import lucia from '../imgs/team/lucia.webp';
import philip from '../imgs/team/philip.webp';

export default function About() {
	return (
		<>
			<Helmet>
				<title>Sobre nós - Service Way Digital: Serviços Empresariais e Soluções de TI</title>
				<meta name="description" content="Nossa missão é impulsionar seu sucesso por meio de soluções de TI inovadoras e personalizadas" />
				<meta name="keywords" content="solução de TI em equipe, equipe de especialistas, sobre, missão, soluções de TI, negócios" />
			</Helmet>

			<SectionPageTitle
				pageTitle={'Sobre Nós'} 
				subTitle={'Nossa história'}
			/>

			<section className="container grid g-2">
				<div className="print flex">
					<img src={mission} alt="Sobre nós - Service Way Digital: modelo de site HTML de serviços empresariais e soluções de TI" />
				</div>

				<article>
					<h1>Quem <span className="txt-gradient">Nós Somos</span></h1>

					<h2>Muito mais que uma equipe de TI</h2>
					<p>Bem-vindo à Service Way Digital, onde a inovação encontra a confiabilidade. Fundados nos princípios da tecnologia de ponta e no compromisso inabalável com a excelência, somos o seu parceiro de confiança para serviços empresariais e soluções de TI.</p>
					<p>Na Service Way Digital, somos uma equipe de profissionais apaixonados e dedicados a capacitar empresas com soluções tecnológicas transformadoras. Com um rico histórico de parcerias de sucesso e uma abordagem inovadora, nos esforçamos para estar na vanguarda do cenário tecnológico em constante evolução.</p>

					<h2>Nossa missão</h2>
					<p className="mb-3">Nossa missão é impulsionar seu sucesso por meio de soluções de TI inovadoras e personalizadas. Acreditamos no poder da tecnologia para impulsionar os negócios e estamos comprometidos em fornecer soluções que não apenas atendam, mas superem suas expectativas.</p>
				</article>
			</section>
			
			{/* <section className="accent-end">
				<div className="container mb-8">
					<h1 className="txt-gradient">Temos equipe especializada</h1>
					<div className="grid gtc-4 g-2">
						<article>
							<img src={daniel} className="mb-1" alt="Service Way Digital team" />
							<h2 className="mb-0">Daniel Grayson</h2>
							<p>CEO</p>
							<SocialLinks />
						</article>
						
						<article>
							<img src={lucia} className="mb-1" alt="Service Way Digital team" />
							<h2 className="mb-0">Lucia Mason</h2>
							<p>Head Manager</p>
							<SocialLinks />
						</article>

						<article>
							<img src={philip} className="mb-1" alt="Service Way Digital team" />
							<h2 className="mb-0">Philip Jopher</h2>
							<p>Developer</p>
							<SocialLinks />
						</article>

						<article>
							<img src={albert} className="mb-1" alt="Service Way Digital team" />
							<h2 className="mb-0">Albert Coffin</h2>
							<p>Chief Expert</p>
							<SocialLinks />
						</article>
					</div>
				</div>
			</section> */}


			<section className="container">
				<h1 className="txt-center mb-4">Perguntas <span className="txt-gradient">Frequentes</span></h1>

				<div className="grid g-2 mb-8">
					<article>
						{faqsList.slice(0, 4).map((faq, idx) =>
							<Faq
								key={idx}
								question={faq.question}
								answer={faq.answer}
							/>
						)}
					</article>

					<ul>
						<li>
							<h3 className='mb-3'>Service Way Digital - Soluções:</h3>
						</li>
						<li>
							<h2><span className="pictogram" /> Consultoria de TI</h2>
						</li>
						<li>
							<h2><span className="pictogram" /> Desenvolvimento de software</h2>
						</li>
						<li>
							<h2 className="mb-0"><span className="pictogram" /> Cibersegurança</h2>
						</li>
					</ul>
				</div>

<CallToAction
	icon={<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z"/></svg>}
	text="Estamos proporcionando a melhor experiência ao cliente"
	btnLabel="Entre em contato conosco agora"
	btnUrl="/contact"
/>
			</section>

		</>
	);
}