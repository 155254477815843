import { Helmet } from 'react-helmet';
import CallToAction from '../components/CallToAction';

export default function Policy() {
	return (
		<>
			<Helmet>
				<title>Policy - Service Way Digital: Empresa de Tecnologia da Informação</title>
			</Helmet>

			
			<section className="container">
				<h1>Política de Privacidade</h1>
				<h3>Quem somos</h3>
				<p>O endereço do nosso site é: https://servicewaydigital.com.</p>

				<h3>Comentários</h3>
				<p>Quando os visitantes deixam comentários no site, coletamos os dados mostrados no formulário de comentários, além do endereço IP do visitante e da string do agente do usuário do navegador para ajudar na detecção de spam.</p>

				<h3>Média</h3>
				<p>Se você carregar imagens no site, deve evitar carregar imagens que incluam dados de localização incorporados (EXIF GPS). Os visitantes do site podem baixar e extrair quaisquer dados de localização das imagens no site.</p>

				<h3>Cookies</h3>
				<p>Se deixar um comentário em nosso site, pode optar por salvar seu nome, endereço de email e site em cookies. Eles são usados para sua conveniência, para que você não precise inserir seus detalhes novamente ao deixar outro comentário. Esses cookies durarão um ano.</p>

				<h3>Conteúdo incorporado de outros sites</h3>
				<p>Os artigos neste site podem incluir conteúdo incorporado (por exemplo, vídeos, imagens, artigos, etc.). O conteúdo incorporado de outros sites se comporta da mesma maneira como se o visitante tivesse visitado o outro site.</p>
				<p>Esses sites podem coletar dados sobre você, usar cookies, incorporar rastreamento adicional de terceiros e monitorar sua interação com eles, incluindo o rastreamento de sua interação com o conteúdo incorporado, se você tiver uma conta e estiver conectado a esses sites.</p>

				<h3>Por quanto tempo mantemos seus dados</h3>
				<p>Se você deixar um comentário, o comentário e seus metadados serão retidos indefinidamente. Isso é para que possamos reconhecer e aprovar automaticamente quaisquer comentários subsequentes, em vez de mantê-los em uma fila de moderação.</p>
				<p>Para usuários que se registram em nosso site (se houver), também armazenamos as informações pessoais que eles fornecem em seus perfis de usuário. Todos os usuários podem ver, editar ou excluir suas informações pessoais a qualquer momento (exceto o nome de usuário, que não podem alterar). Os administradores do site também podem ver e editar essas informações.</p>

				<h3>Quais são seus direitos sobre seus dados</h3>
				<p>Se você tem uma conta neste site ou deixou comentários, pode solicitar um arquivo exportado dos dados pessoais que mantemos sobre você, incluindo quaisquer dados que nos tenha fornecido. Você também pode solicitar que excluamos todos os dados pessoais relacionados a você. Isso não inclui os dados que somos obrigados a manter para fins administrativos, legais ou de segurança.</p>
			</section>


			<CallToAction
				icon={<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z"/></svg>}
				text="Estamos proporcionando a melhor experiência ao cliente"
				btnLabel="Entre em contato conosco agora"
				btnUrl="/contact"
				customClass="mb-8"
			/>
		</>
	);
}