import ContactInfo from './ContactInfo';
import ContactForm from '../components/ContactForm';

export default function SectionContact({ bg=true }) {
	return (
		<section className={bg ? 'accent-end' : ''}>
			<div className="container">
				{bg &&
					<h1 className="txt-gradient">Contate-nos</h1>
				}

				<div className="grid gtc-1-2 g-2">
					<div>
						<h3 className="mb-2">Para solicitar mais informações, entre em contato conosco</h3>
						<ContactInfo title={false} />
					</div>

					<ContactForm />
				</div>
			</div>
		</section>
	);
}